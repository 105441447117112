import * as PIXI from 'pixi.js';
import gsap from 'gsap';

export default function disegnaVolti(x,y,scaleX,scaleY,sprite,filtro,finestra){
    if(document.querySelectorAll(finestra).length>0){
        if(!document.querySelector(finestra).classList.contains('disegnato')){
            let app = new PIXI.Application({ 
                width: x, 
                height: y,
                backgroundAlpha: 0
            });
            document.querySelector(finestra).appendChild( app.view ); 
            var displacementSprite  = new PIXI.Sprite.from( filtro );
            var displacementFilter  = new PIXI.DisplacementFilter( displacementSprite );     

            displacementSprite.texture.baseTexture.wrapMode = PIXI.WRAP_MODES.REPEAT;

            var image  = new PIXI.Sprite.from( sprite );
            image.scale.x=scaleX;
            image.scale.y=scaleY;
            
            displacementFilter.scale.set(70);
            displacementFilter.autoFit = true;
            app.stage.filterArea = app.screen;
            app.stage.filters = [displacementFilter];
            displacementSprite.anchor.set(0.5);
            displacementSprite.scale.set(0.05); 
            app.stage.addChild(image,displacementSprite);

            const tl = new gsap.timeline()
            .set(image,{alpha:0})
            .set(displacementFilter.scale,{ x: 450, y: 900})
            .set(displacementFilter,{rotation:10})
            .set(displacementSprite.scale,{ x: 2, y: 4})
            .to(displacementFilter,1.5,{rotation:0},'ripple')
            .to(displacementFilter.scale,1.5,{ x: 1, y: 1},'ripple')
            .to(image,1.5,{ alpha:1 },"ripple")
            .to(displacementSprite.scale,1.5,{ x: 20, y: 20},'ripple');

            document.querySelector(finestra).classList.add('disegnato');
        }
    }
}