<template>
  <HeaderDamand />
  <MenuDamand />
  <router-view/>
  <FooterDamnd />
  <NewsletterForm />
</template>

<script>
//  stile
import './assets/css/damand.css'
// script
import HeaderDamand from '@/components/HeaderDamand.vue';
import MenuDamand from '@/components/MenuDamand.vue';
import FooterDamnd from '@/components/Footer.vue';
import NewsletterForm from '@/components/NewsletterForm.vue';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { SplitText } from 'gsap/SplitText';
gsap.registerPlugin(ScrollTrigger,SplitText);
export default {
  components: {
    NewsletterForm,
    HeaderDamand,
    MenuDamand,
    FooterDamnd
  },
  methods:{   
    
  },
  mounted(){
  }
}
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
