import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import TutorialDamand from "@/views/TutorialDamand.vue";

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/chi-siamo',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutDamand.vue')
  },
  {
    path: '/progetto',
    name: 'progetto',
    component: () => import(/* webpackChunkName: "progetto" */ '../views/ProgettoDamand.vue')
  },
  {
    path: '/soluzioni',
    name: 'soluzioni',
    component: () => import(/* webpackChunkName: "soluzioni" */ '../views/SoluzioniDamand.vue')
  },
  {
    path: '/i-nostri-store',
    name: 'store',
    component: () => import(/* webpackChunkName: "store" */ '../views/StoreDamand.vue')
  },
  {
    path: '/contatti',
    name: 'contatti',
    component: () => import(/* webpackChunkName: "contatti" */ '../views/ContactDamand.vue')
  },
  {
    path: '/tutorial',
    name: 'tutorial',
    component: TutorialDamand
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
