<template>
  <div class="wrapper">
    <section class="intro">
      <div class="container-damand">
        <div class="block-intro">
          <div class="video-sfondo-intro">
            <video muted playsinline loop autoplay>
              <source src="@/assets/video/video-damand-home.mp4" type="video/mp4" />
            </video>
          </div>
          <h1 class="title-intro titolo-animato">Shampoo e balsamo solidi personalizzati</h1>
          <a href="#inizia" class="btn-text w-button">Per il benessere di capello e cute</a>
          <div class="linea-scroll"></div>
          <div id="dec-1-home" class="decorazione-home-1">
          </div>
        </div>
        <div id="castana"></div>
        <div class="linea-scroll black"></div>
      </div>
    </section>
    <section class="section" id="inizia">
      <div class="container-damand">
        <h2 class="h2 center titolo-animato">DAMAND, una scelta <br>che rispetta la persona <br>e il pianeta</h2>
      </div>
    </section>
    <section class="section">
      <div class="container-damand">
        <div class="eq-height w-row">
          <div class="w-col w-col-6">
            <div class="box-immagine">
              <div class="box-acqua">
                <div data-blotter="" class="lettera-grande"><span class="content__text-inner">M</span></div>
              </div>
              <div class="maschera-img"><img src="@/assets/img/prodotto-home-1-a.webp" loading="lazy" alt="" class="img"></div>
            </div>
          </div>
          <div class="w-col w-col-6">
            <div class="block-text">
              <p class="p" data-ix="fadein">Shampoo e balsamo solidi con componenti vegetali sono la soluzione rivoluzionaria per avere un capello brillante e vigoroso e un cuoio capelluto perfettamente in salute. In collaborazione con saloni di parrucchiere attentamente selezionati, DAMAND offre consulenza qualificata per la creazione di <strong>shampoo</strong> <strong>e</strong> <strong>balsamo</strong> <strong>solidi</strong> <strong>sostenibili</strong> <strong>personalizzati</strong> idonei alle esigenze della tua cute. Per dire addio per sempre a capelli sciupati, irritazioni e fastidi.</p>
              <router-link class="btn btn-ani w-inline-block" :to="{name:'progetto'}">
                <div>Scopri il progetto</div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section pad-bottom pad-top">
      <div class="container-damand">
        <div class="eq-height w-row row-soluzioni">
          <div class="w-col w-col-6">
            <div class="block-text no-pad m-bottom-mobile">
              <p class="p" data-ix="fadein">Un team giovane contraddistinto da uno spirito innovativo, specializzato nella realizzazione di <strong>shampoo</strong> <strong>e balsamo</strong> <strong>solidi</strong> <strong>100%</strong> <strong>Made</strong> <strong>in</strong> <strong>Italy</strong> studiati su misura in funzione del capello e della cute di ogni singola persona. Partner di progetto sono negozi di parrucchiere selezionati, per una squadra vincente al servizio dei nostri utenti.</p>
              <router-link class="btn btn-ani w-inline-block" :to="{name:'about'}">
                <div>Scopri chi siamo</div>
              </router-link>
            </div>
          </div>
          <div class="w-col w-col-6">
            <div class="box-immagine">
              <div class="box-acqua a">
                <div data-blotter="" class="lettera-grande"><span data-blotter="" class="content__text-inner">a</span></div>
              </div>
              <div class="maschera-img"><img src="@/assets/img/prodotto-home-2.webp" loading="lazy" alt="" class="img m-left"></div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="cta scatta-cta">
      <div class="box-acqua d">
        <div data-blotter="" class="lettera-grande"><span class="content__text-inner">D</span></div>
      </div>
      <div id="rossa"></div>
      <div class="w-row">
        <div class="border-right w-col w-col-6">
          <div class="box-cta" data-ix="fadein"><img src="@/assets/img/logo-bianco.svg" loading="lazy" alt="" class="logo-icona icona-anim">
            <h3 class="title-cta titolo-animato">Vuoi sapere di più sulla filosofia DAMAND?</h3>
            <router-link class="btn-round btn-ani w-button" :to="{name:'store'}">
              <div>Trova un nostro salone</div>
            </router-link>
          </div>
        </div>
        <div class="w-col w-col-6">
          <div class="box-cta" data-ix="fadein"><img src="@/assets/img/logo-bianco.svg" loading="lazy" alt="" class="logo-icona icona-anim">
            <h3 class="title-cta titolo-animato">Sei un professionista e desideri informazioni?</h3>
            <router-link class="btn-round btn-ani w-button" :to="{name:'contatti'}">
                <div>Contattaci</div>
            </router-link>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { SplitText } from 'gsap/SplitText';
gsap.registerPlugin(ScrollTrigger,SplitText);
import rossa from '@/assets/img/rossa.svg';
import castana from '@/assets/img/castana.svg';
import cloud from '@/assets/img/bump/fibers.jpg';
import disegnaVolti from '@/assets/js/animazioni';
export default {
  name: 'HomeView',
  data(){
    return{
      wW:0,
      titolo:'Creazione shampoo e balsamo solidi personalizzati | DAMAND',
      descrizione:'Shampoo e balsamo solidi sostenibili su misura | Consulenza specializzata | In collaborazione con parrucchieri selezionati | Richiedi info',
      imgRossa:{
        x:400,
        y:400,
        scale:0.75
      },
      imgCastana:{
        x:500,
        y:500,
        scale:0.7
      }
    }
  },
  components: {

  },
  methods:{
    scrollImmagini(){
      //  gestione immagini
      let $boxImg = document.querySelectorAll(".maschera-img");
      const $hB = $boxImg[0].offsetHeight;
      let $offset = $hB-(($hB*10)/100);
      $offset = '-'+$offset;
      console.log($offset);
      $boxImg.forEach((element) => {
        const $img = element.querySelector("img");
        gsap.set($img, {y:$offset});
        gsap.to($img, {
          y: 0,
          ease: "linear",
          scrollTrigger: {
            trigger: element,
            scrub: 1,
            start: "top center",
            end: "+=300"
          }
        });
      });
    },
    scrollTesto(){
      const $p = document.querySelectorAll("p");
      const $titoli = document.querySelectorAll(".titolo-animato");
      const split = new SplitText($p, { type: "lines" });
      const splitTitoli = new SplitText($titoli, { type: "words" });
      split.lines.forEach((target) => {
        gsap.to(target, {
          backgroundPositionX: 0,
          ease: "none",
          scrollTrigger: {
            trigger: target,
            scrub: 1,
            start: "top center",
            end: "bottom center"
          }
        });
      });
      splitTitoli.words.forEach((target) => {
        gsap.to(target, {
          opacity: 1,
          ease: "none",
          scrollTrigger: {
            trigger: target,
            scrub: 1,
            start: "top center",
            end: "bottom center"
          }
        });
      });
    },
    scrollBottoni(){
      const $btn = document.querySelectorAll('.btn-ani');
      $btn.forEach((target) => {
        gsap.to(target, {
          opacity: 1,
          ease: "none",
          duration:1,
          scrollTrigger: {
            trigger: target,
            scrub: 1,
            start: "top center",
            end: "bottom center"
          }
        });
      });
    },
    scrollIcone(){
      const $btn = document.querySelectorAll('.icona-anim');
      $btn.forEach((target) => {
        gsap.to(target, {
          scale: 1,
          ease: "none",
          duration:1,
          scrollTrigger: {
            trigger: target,
            scrub: 1,
            start: "top center",
            end: "bottom center"
          }
        });
      });
    }
  },
  mounted(){
    window.scrollTo(0, 0);
    //gestione meta
    document.title =this.titolo;
    document.querySelector('meta[name="description"]').setAttribute("content", this.descrizione);
    document.querySelector('meta[property="og:title"]').setAttribute("content", this.titolo);
    document.querySelector('meta[property="og:description"]').setAttribute("content", this.descrizione);
    //gestione mobile
    this.wW=window.innerWidth;
    if(this.wW<1200 && this.wW>991){
      this.imgRossa.x=400;
      this.imgRossa.y=400;
      this.imgRossa.scale=400;
      this.imgCastana.x=500;
      this.imgCastana.y=500;
      this.imgCastana.scale=0.5;
    }else if(this.wW<991 && this.wW>767){
      this.imgRossa.x=400;
      this.imgRossa.y=400;
      this.imgRossa.scale=400;
      this.imgCastana.x=500;
      this.imgCastana.y=500;
      this.imgCastana.scale=0.5;
    }else if(this.wW<767 && this.wW>567){
      this.imgRossa.x=300;
      this.imgRossa.y=300;
      this.imgRossa.scale=0.3;
      this.imgCastana.x=250;
      this.imgCastana.y=250;
      this.imgCastana.scale=0.25;
    }else if(this.wW<567){
      this.imgRossa.x=250;
      this.imgRossa.y=250;
      this.imgRossa.scale=0.4;
      this.imgCastana.x=200;
      this.imgCastana.y=200;
      this.imgCastana.scale=0.3;
    }
    document.body.classList.remove('dark', 'prodotto');
    //animazioni
    this.scrollTesto();
    this.scrollImmagini();
    this.scrollIcone();
    this.scrollBottoni();
    //volti
    ScrollTrigger.create({
      trigger: '.block-intro',
      onEnter: ()=> disegnaVolti(this.imgCastana.x,this.imgCastana.y,this.imgCastana.scale,this.imgCastana.scale,castana,cloud,'#castana'),
      scrub: true,
      start: "top center",
      end: "bottom center"
    }),
    ScrollTrigger.create({
      trigger: '.scatta-cta',
      onEnter: ()=> disegnaVolti(this.imgRossa.x,this.imgRossa.y,this.imgRossa.scale,this.imgRossa.scale,rossa,cloud,'#rossa'),
      scrub: true,
      start: "top center",
      end: "bottom center"
    })
  },
}
</script>

<style>
.svg-filters{
  display:none;
}
.maschera-img{
  position: relative;
  overflow: hidden;
  height: 700px;
}
#rossa{
  position: absolute;
  top: -400px;
  left: 20px;
  width: 400px;
  height: 400px;
}
#castana{
  position: absolute;
  bottom: -100px;
  right: -150px;
  width: 400px;
  height: 400px;
  z-index: 4;
}
@media only screen and (max-width:567px) {
  .maschera-img{
    height: 600px;
  }
  #castana{
    bottom: -100px;
    right: -66px;
    width: 200px;
    height: 200px;
  }
  .linea-scroll{
    bottom: -50px;
    height: 100px;
  }
  .row-soluzioni{
    display: flex;
    flex-direction: column;
  }
  .row-soluzioni .w-col-6:first-child{
    order: 2;
  }
  .row-soluzioni .w-col-6:nth-child(2){
    order: 1;
  }
  #rossa{
    top: -250px;
    width: 250px;
    height: 250px;
  }
}
</style>