<template>
    <div class="menu-open">
    <div class="w-layout-blockcontainer container-menu w-container">
      <div class="box-link-menu">
        <router-link class="link-menu w-inline-block" :to="{name:'home'}" @click="closeMenuModale">
          <div>HOME</div>
        </router-link>
      </div>
      <div class="box-link-menu">
        <router-link class="link-menu w-inline-block" :to="{name:'about'}" @click="closeMenuModale">
          <div>azienda</div>
        </router-link>
      </div>
      <div class="box-link-menu">
        <router-link class="link-menu w-inline-block" :to="{name:'progetto'}" @click="closeMenuModale">
          <div>progetto</div>
        </router-link>
      </div>
      <div class="box-link-menu">
        <router-link class="link-menu w-inline-block" :to="{name:'soluzioni'}" @click="closeMenuModale">
          <div>soluzioni</div>
        </router-link>
      </div>
      <div class="box-link-menu">
        <router-link class="link-menu w-inline-block" :to="{name:'store'}" @click="closeMenuModale">
          <div>store</div>
        </router-link>
      </div>
      <div class="box-link-menu">
        <router-link class="link-menu w-inline-block" :to="{name:'contatti'}" @click="closeMenuModale">
          <div>contatti</div>
        </router-link>
      </div>
    </div>
    <div class="menu-social">
      <a href="https://www.instagram.com/damand____/" target="_blank" class="link-social-menu w-inline-block">
        <div>Instagram</div>
      </a>
    </div>
   <TestaMenu />
  </div>
</template>

<script>
import gsap from 'gsap';
import TestaMenu from '@/components/TestaMenu.vue';
export default {
  name: 'MenuDamand',
  data(){

  },
  props:{
    
  },
  components:{
    TestaMenu
  },
  methods:{
    closeMenuModale(){
      const $menu = document.querySelectorAll('.menu-icona')[0];
      const $menuModal = document.querySelector('.menu-open');
      const $header = document.querySelector('.header');
      $menuModal.style.transform = 'translateY(-200%)';
      $menu.classList.remove('active');
      $header.classList.remove('aperto')
    }
  },
  mounted(){
    let $timeoutId;
    let $playAnimazione=true;
    const $tl = gsap.timeline();
    $tl.to('#filterTesta feTurbulence', {attr: { baseFrequency: 0.05 },duration:1,ease:'circ.in',repeat:-1,yoyo:true});
    $tl.pause();
    const $testaMenu = document.querySelector('.testa-menu');
    document.addEventListener("mousemove", function(event) {
      //  azzera conteggio
      clearTimeout($timeoutId);
      //  movimento mouse
      const $header = document.querySelector('.header');
      if($header.classList.contains('aperto')){
            var mouseX = event.clientX;
            var mouseY = event.clientY;
            $testaMenu.style.left = mouseX + "px";
            $testaMenu.style.top = mouseY + "px";
            let $deltaAnimazione = mouseX/1000;
            if($playAnimazione){
              $tl.play();
            }
            $playAnimazione=false;
      }
      //  inizia conteggio
      $timeoutId = setTimeout(() => {
        $playAnimazione=true;
        $tl.pause();
        gsap.to('#filterTesta feTurbulence', {attr: { baseFrequency: 0 },ease:'circ.in'});
      }, 450);
    }) 
  }
}
</script>

<style>
.testa-menu{
  position: absolute;
  width: 200px;
  height: 200px;
  pointer-events: none;
  transform: translate(-30%,-80%);
}
</style>