<script>
export default {
  name: 'TuorialDamand'
}
</script>

<template>
  <section class="sezione-tutorial">
    <div class="w-layout-blockcontainer w-container">
      <div id="w-node-_32172e2b-5a5a-99be-6c82-7aced95c9067-b829e3fc" class="w-layout-layout wf-layout-layout">
        <div id="w-node-_1152c3f0-01f5-9ec0-2f25-5e6b2484b161-b829e3fc" class="w-layout-cell">
          <div class="embed-video w-embed"><video width="100%" height="100%" class="video-tutorial" controls="">
            <source src="@/assets/video/video-tutorial-corso.mp4" type="video/mp4">
          </video></div>
        </div>
        <div id="w-node-_541c08b7-c59c-7b03-87d5-cd0c4c40566f-b829e3fc" class="w-layout-cell">
          <div class="text-block-tutorial">
            <div>
              <h2 class="h2">Tutorial</h2>
              <h4 class="desc-tutorial">Guarda il video, usa lo shampoo e il balsamo per una settimana e poi raccontaci la tua esperienza compilando il questionario</h4>
              <a href="https://forms.gle/yCQgKoZbBkM6n7MY8" target="_blank" class="btn-round black btn-questionario w-button">compila il questionario</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style>

</style>