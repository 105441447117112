<script>
export default {
  name: 'NewsletterForm',
  props: {},
  methods:{
    closeNewsletter(){
      const popup = document.querySelector('.popup-newsletter');
      popup.style.display='none';
    }
  }
}
</script>

<template>
  <div class="popup-newsletter">
    <div class="close-newsletter" @click="closeNewsletter()">X</div>
    <div id="omnisend-embedded-v2-6524f6caa169cf3209a51fc6"></div>
  </div>
</template>

<style>
#omnisend-embedded-v2-6524f6caa169cf3209a51fc6{
  box-shadow: 0 0 12px 4px #0000005c !important;
}
.popup-newsletter{
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  width: 50%;
  height: 90vh;
  transform: translate(-50%,-50%);
  z-index: 99;
}
.omnisend-form-6524f6caa169cf3209a51fc6-content, .omnisend-form-6524f6caa169cf3209a51fc6-sections-container{
  max-width: 100% !important;
  height: 100% !important;
}
.omnisend-form-6524f6caa169cf3209a51fc6-image-container-652bbc237391a173a9d403e6 img{
  width: 100% !important;
  height: 400px !important;
  object-fit: cover;
}
.close-newsletter{
  position: absolute;
  top: 10px;
  right: 10px;
  color: #212121;
  z-index: 2;
  width: 25px;
  height: 25px;
  border: 1px solid #212121;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  cursor: pointer;
}
@media screen and (max-width:794px) {
  .popup-newsletter{
    width: 95%;
    overflow-y: scroll;
  }
}
</style>